"use client";
import React from "react";

const useSsr = () => {
    const isDOM = typeof window !== "undefined" && window.document && window.document.documentElement;

    return {
        isBrowser: isDOM,
        isServer: !isDOM,
    };
};

export default useSsr;

import { definitions } from "@/services/metruyenvl";
import type { CollapseProps } from "antd";
import { Collapse } from "antd";
import Link from "next/link";
import React from "react";
import { HiMiniBookOpen } from "react-icons/hi2";

function CollapseCategory() {
    const listCategory = JSON.parse(localStorage.getItem("category") ?? "");
    const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

    const items: CollapseProps["items"] = [
        {
            key: "1",
            label: (
                <div className="flex items-center gap-x-4 p-3 border-b border-solid border-slate-200 ">
                    <HiMiniBookOpen size={23} color="#b78a28" /> <p className="font-semibold text-base"> Thể loại</p>
                </div>
            ),
            children: (
                <div>
                    {listCategory?.map((item: definitions["do.TagDO"]) => {
                        return (
                            <div key={item.id} className="p-2 text-slate-400 text-base font-semibold">
                                <Link href={`/the-loai/${item.slug}/${item.id}`}>{item.name}</Link>
                            </div>
                        );
                    })}
                </div>
            ),
        },
    ];
    return (
        <Collapse
            ghost
            items={items}
            expandIcon={() => {
                return <></>;
            }}
        />
    );
}

export default CollapseCategory;

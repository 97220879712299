import React from "react";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Form } from "antd";
import { GoogleOAuthProvider, GoogleLogin, useGoogleOneTapLogin, useGoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookSquare } from "react-icons/fa";
import authAPI from "@/services/auth_api";
import { cookies } from "next/headers";
import { definitions } from "@/services/metruyenvl";

interface typeResponseGoogle {
    token?: {
        token?: string;
        refresh_token?: string;
    };
    user?: definitions["do.UserDO"];
}

function LoginSocial() {
    const responseFacebook = (response: any) => {
        console.log(response);
    };
    const responseGoogle = (response: any) => {
        console.log(response);
    };

    return (
        <div>
            <div id="login-gg" className="w-full"></div>
            <Form.Item>
                <GoogleOAuthProvider clientId={`${process.env.NEXT_PUBLIC_GOOGLE_ID}`}>
                    <GoogleLogin
                        onSuccess={async (credentialResponse) => {
                            const res = await authAPI.swap3rdToken("google", credentialResponse.credential as string);
                            localStorage.setItem("user_info", JSON.stringify(res.data) ?? "");
                            localStorage.setItem("access_token", res.data?.token?.token ?? "");
                            localStorage.setItem("refresh_token", res.data?.token?.refresh_token ?? "");
                            setTimeout(() => {
                                window.location.reload();
                            }, 1500);
                        }}
                        onError={() => {
                            console.log("Login Failed");
                        }}
                    />
                </GoogleOAuthProvider>
                {/* href={`${process.env.NEXT_PUBLIC_API_URL}/auth/complete`} */}
                {/* <div className="hover:text-black cursor-pointer h-10 w-full rounded-full border border-stone-200 text-lg justify-center gap-x-2 font-semibold flex items-center">
                    Đăng nhập Google <FcGoogle size={20} />
                </div> */}
            </Form.Item>
            {/* <Form.Item>
        <FacebookLogin
          appId={`386600433224574`}
          callback={responseFacebook}
          render={(renderProps) => (
            <div
              onClick={renderProps.onClick}
              className="cursor-pointer h-10 w-full rounded-full border border-stone-200 text-lg justify-center gap-x-2 font-semibold flex items-center"
            >
              Đăng nhập Facebook <FaFacebookSquare size={20} color="blue" />
            </div>
          )}
        />
      </Form.Item> */}
        </div>
    );
}

export default LoginSocial;

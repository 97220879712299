import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { definitions } from "@/services/metruyenvl";

const initialState = {
    bgColor: "#E1F0DA",
    contentColor: "#D4E7C5",
    buttonColor: "",
    bannerImage: {
        id: "",
        alt: "alt for banner",
        book_id: "book_id",
        direct_url: "/",
        height: 100,
        image: "",
        width: 100,
        created_time: 0,
        updated_time: 0,
    } as definitions["models.BannerMD"],
};

const themeSlice = createSlice({
    name: "theme-color",
    initialState,
    reducers: {
        setTheme: (state, action) => {
            state.bgColor = action.payload.bgColor;
            state.contentColor = action.payload.contentColor;
        },
        setBannerConfig: (state, action) => {
            state.bannerImage = action.payload;
        },
    },
});

export const { setTheme, setBannerConfig } = themeSlice.actions;

export const themeColor = (state: RootState) => state.themeReducer;

export default themeSlice.reducer;

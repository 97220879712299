import { HeadModule } from "@/layouts/styles/ssr.style";
import { Input, Modal, Button } from "antd";
import authAPI from "@/services/auth_api";
import React, { useState } from "react";
import alertUtils from "@/utils/alert";
interface IForget {
    onCancelCallback?: any;
}

function ForgetPassword({ onCancelCallback }: IForget) {
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const handleOk = async () => {
        setLoading(true);
        setEmail("");
        try {
            const res = await authAPI.forgotPassword(email);
            alertUtils.toastSuccess("Vui lòng kiểm tra email của bạn");
            setLoading(false);
        } catch (error: any) {
            alertUtils.toastError("Không tìm thấy email của bạn");
            // alertUtils.toastError(error.response.data.data.message);
            setLoading(false);
        }
    };
    const onCancel = () => {
        setVisible(false);
    };
    return (
        <div>
            <label
                className="text-orange-300"
                onClick={() => {
                    setVisible(true);
                    onCancelCallback();
                }}
            >
                Quên mật khẩu
            </label>
            <Modal open={visible} onOk={handleOk} onCancel={onCancel} style={{ overflow: "hidden" }} footer={null}>
                <div style={{ marginTop: "25px", padding: "0px 40px" }}>
                    <HeadModule>Quên mật khẩu</HeadModule>
                    <Input
                        className="rounded-full h-10 border border-solid border-slate-200 mb-4"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        placeholder="Nhập email của bạn"
                    />
                    <Button onClick={handleOk} type="primary" loading={loading} className="h-10 w-full rounded-full text-white text-lg font-semibold hover:text-white" style={{ background: "#b78a28" }}>
                        Gửi mật khẩu
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export default ForgetPassword;

import * as CryptoJS from "crypto-js";

export class Cryptor {
    secretKey: string;
    initVector: string;

    constructor(initVector: string, secretKey: string) {
        this.initVector = initVector;
        this.secretKey = secretKey;
    }

    public DesDecryption(base64: string): string {
        try {
            const decryptedData = CryptoJS.AES.decrypt(
                {
                    ciphertext: CryptoJS.enc.Base64.parse(base64),
                } as CryptoJS.lib.CipherParams,
                CryptoJS.enc.Utf8.parse(this.secretKey),
                {
                    iv: CryptoJS.enc.Utf8.parse(this.initVector),
                }
            );

            const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);

            return decryptedText;
        } catch (error) {
            console.error("Error decrypting data:", error);
            throw new Error("Decryption failed");
        }
    }
}

"use client";
import React, { useEffect, useState } from "react";
import { IModalUser } from "@/types/constants";
import LoginSocial from "@/modules/login-social";
import { useAuth } from "@/hooks";
import ForgetPassword from "@/components/forgot-password";
import type { FormInstance } from "antd";
import { Modal, Checkbox, Form, Input, Button } from "antd";

const AuthForm: React.FC<IModalUser> = (props) => {
    const { loginAuth, registerAuth, loginSocial } = useAuth();
    const { visible, type, onCancel } = props;
    const [formLogin] = Form.useForm();
    const [userSave, setUserSave] = useState<{ email?: string; password?: string; remember?: boolean }>({});

    const [changeType, setChangeType] = useState<boolean>(type);
    useEffect(() => {
        const rememberUser = JSON?.parse(localStorage.getItem("remember_user") ?? "{}");
        if (rememberUser) {
            setUserSave(rememberUser);
        }
        if (rememberUser && !type) {
            formLogin.setFieldValue("email", rememberUser.email);
            formLogin.setFieldValue("password", rememberUser.password);
            formLogin.setFieldValue("remember", rememberUser.remember);
        } else {
            onReset();
        }
        if (type === true) {
            onReset();
        }
        setChangeType(type);
    }, [type, visible]);

    const handleOk = () => {};
    const onFinish = (values: any) => {
        changeType ? registerAuth(values.full_name, values.email, values.password, values.remember) : loginAuth(values.email, values.password);
        if (values.remember) {
            localStorage.setItem("remember_user", JSON.stringify(values));
        } else {
            localStorage.removeItem("remember_user");
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    const onReset = () => {
        formLogin.resetFields();
    };

    return (
        <Modal
            forceRender
            open={visible}
            onOk={handleOk}
            onCancel={onCancel}
            style={{ overflow: "hidden" }}
            footer={
                <div>
                    {!type && (
                        <div className="flex justify-center items-center font" style={{ width: "110%", height: "54px", marginLeft: "-5%", marginBottom: "-5%", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px", backgroundColor: "#f7f7f7", fontSize: "16px" }}>
                            Chào mừng đến với thế giới truyện
                        </div>
                    )}
                </div>
            }
        >
            <div className="flex justify-center items-center gap-x-8">
                <div
                    className="cursor-pointer text-2xl font-medium  hover:text-slate-500"
                    style={{ color: `${changeType ? "#999999" : "black"}` }}
                    onClick={() => {
                        if (userSave) {
                            formLogin.setFieldsValue({
                                email: userSave.email,
                                password: userSave.password,
                                remember: userSave.remember,
                            });
                        }
                        setChangeType(false);
                    }}
                >
                    Đăng nhập
                </div>
                <div
                    className="cursor-pointer text-2xl font-medium  hover:text-slate-500"
                    style={{ color: `${changeType ? "black" : "#999999"}` }}
                    onClick={() => {
                        onReset();
                        setChangeType(true);
                    }}
                >
                    Đăng ký
                </div>
            </div>
            <div>
                {" "}
                {/* initialValues={{ email: userSave.email ?? "", password: userSave.password ?? "", remember: true }} */}
                <Form form={formLogin} name={type ? "register" : "login"} style={{ marginTop: "25px", padding: "0px 40px" }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
                    <div className="flex justify-between">
                        <label>Email</label>
                    </div>
                    <Form.Item name="email" rules={[{ type: "email", required: true, message: "Vui lòng nhập email và đúng định dạng!" }]}>
                        <Input className="rounded-full h-10 border border-solid border-slate-200 mt-1" />
                    </Form.Item>

                    <div className="flex justify-between">
                        <label>Mật khẩu</label>
                        {!changeType && <ForgetPassword onCancelCallback={onCancel} />}
                    </div>
                    <Form.Item name="password" rules={[{ required: true, message: "Mật khẩu phải lớn hơn 4 kí tự!" }]}>
                        <Input.Password className="rounded-full h-10 border border-solid border-slate-200 mt-1" />
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked">
                        <Checkbox> Ghi nhớ mật khẩu</Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <button typeof="submit" className="h-10 w-full rounded-full text-white text-lg font-semibold hover:text-white" style={{ background: "#b78a28" }}>
                            {changeType ? "Đăng ký" : "Đăng nhập"}
                        </button>
                    </Form.Item>
                    {!changeType && <LoginSocial />}
                </Form>
            </div>
        </Modal>
    );
};

export default AuthForm;

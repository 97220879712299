"use client";
import React, { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";

function PolicyFooter() {
    return (
        <div className="xl:h-72 md:mt-0 w-full border-t justify-center border-inherit border-solid flex flex-col gap-4 items-center footer-bg">
            <Image unoptimized={true} src={"/book-30.webp"} alt="" width={30} height={30} className="md:mt-0 mt-4" />
            <div className="text-sm md:w-1/3 w-2/3 text-center"> Mê truyện vl là nền tảng mở trực tuyến, miễn phí đọc truyện chữ được convert hoặc dịch kỹ lưỡng, đóng góp đầy đủ các thể loại nổi bật như là kiếm hiệp, huyền huyễn, ngôn tình...</div>
            {/* <div className="flex items-center gap-8">
        <Image unoptimized={true} src={"/facebook.png"} alt="" width={35} height={35} />
        <Image unoptimized={true} src={"/youtube.png"} alt="" width={35} height={35} />
        <Image unoptimized={true} src={"/twitter.png"} alt="" width={35} height={35} />
        <Image unoptimized={true} src={"/tik-tok.png"} alt="" width={35} height={35} />
      </div>
      <div className="flex md:flex-row flex-col items-center gap-3 text-sm md:mb-0 mb-6">
        <Link href={"/"}>Điều khoản dịch vụ</Link>
        <Link href={"/"}>Chính sách bảo mật</Link>
        <Link href={"/"}>Về bản quyền</Link>
        <Link href={"/"}>Hướng dẫn sử dụng</Link>
        <Link href={"/"}>Tải video từ các mạng xã hội</Link>
      </div> */}

            <div className="flex md:flex-row flex-col items-center gap-3 text-sm md:mb-0 mb-6">
                <Link href={"/"}>Tải video từ các mạng xã hội</Link>
            </div>
        </div>
    );
}

export default PolicyFooter;

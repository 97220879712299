"use client";
import React from "react";
import alertUtils from "@/utils/alert";
import { useRouter } from "next/navigation";
import authAPI from "@/services/auth_api";
import { definitions } from "@/services/metruyenvl";
import axios from "axios";

function useAuth() {
    const getToken = () => {
        let accesstoken = localStorage.getItem("access_token");
        return accesstoken;
    };
    const getRefreshToken = async () => {
        let refreshToken = localStorage.getItem("refresh_token");
        try {
            const res = await authAPI.refreshToken(refreshToken ?? "")
            localStorage.setItem("access_token", res.data?.token ?? "");
            localStorage.setItem("refresh_token", res.data?.refresh_token ?? "");
            return res.data?.token;
        } catch (error) {
            console.log(error);
        }
    };
    let isAuth = typeof window !== "undefined" && localStorage.getItem("access_token") ? true : false;
    const loginAuth = async <T extends string>(email: T, password: T) => {
        try {
            const res = await authAPI.signIn(email, password,);

            localStorage.setItem("user_info", JSON.stringify(res?.data?.user));
            localStorage.setItem("access_token", res?.data?.token ?? "");
            localStorage.setItem("refresh_token", res?.data?.refresh_token ?? "");
            localStorage.removeItem("list_readings");
            setTimeout(() => {
                window.location.reload();
            }, 500);
            return res.data;
        } catch (error) {
            alertUtils.toastError("Sai tên email hoặc mật khẩu!");
        }
    };
    const refreshToken = () => {
        let accesstoken = localStorage.getItem("refresh_token");
        return accesstoken;
    };
    const loginSocial = async <T extends string>(provider: string) => {
        try {
            const res = await authAPI.loginSocial(provider);
            console.log("authAPI::loginSocial - res: ", res);
        } catch (error) {
            console.log("authAPI::loginSocial - error: ", error);
        }
    };
    let listReadings: definitions["do.BookDO"][] = typeof window !== "undefined" && localStorage.getItem("list_readings") ? JSON.parse(localStorage.getItem("list_readings") ?? "[]") : [];
    const registerAuth = async <T extends string>(full_name: T, email: T, password: T, remember: boolean) => {
        try {
            const res = await authAPI.signUp(email, password);
            localStorage.setItem("access_token", res?.data?.token ?? "");
            localStorage.removeItem("list_readings");
            setTimeout(() => {
                window.location.reload();
            }, 500);
            return res.data;
        } catch (error) {
            alertUtils.toastError("Email đã tồn tại!");
        }
    };
    const logout = async () => {
        try {
            const rememberUser = JSON?.parse(localStorage.getItem("remember_user") ?? "{}");
            // localStorage.clear();
            // if (rememberUser) {
            //     localStorage.setItem("remember_user", rememberUser);
            // }
            Object.keys(localStorage).forEach((key) => {
                if (key !== "remember_user") {
                    localStorage.removeItem(key);
                }
            });
            window.location.reload();
            const res = await authAPI.logout();
        } catch (error) {
            console.log(error);
        }
    };

    let profile = {} as definitions["do.UserDO"];
    if (typeof window !== "undefined") {
        const storedUserInfo = localStorage.getItem("user_info");
        if (storedUserInfo) {
            profile = JSON.parse(storedUserInfo);
        }
    }

    return {
        loginSocial,
        getToken,
        getRefreshToken,
        registerAuth,
        refreshToken,
        loginAuth,
        isAuth,
        logout,
        profile,
        listReadings,
    };
}

export default useAuth;

"use client";
import React, { useEffect, useState } from "react";
import { Input, message } from "antd";
import { useAuth, useDebounce } from "@/hooks";
import { useRouter } from "next/navigation";
import { BiSearch } from "react-icons/bi";
import { definitions } from "@/services/metruyenvl";
import { SearchParamsText } from "@/layouts/styles/ssr.style";
import searchAPI from "@/services/search_api";
import { ResponseTypes } from "@/types/constants";

function ButtonSearch() {
    const router = useRouter();
    const { isAuth } = useAuth();
    const [nameQuery, setNameQuery] = useState<string>("");
    const [list, setList] = useState<string[]>([]);
    const [listHistories, setListHistories] = useState<string[]>([]);
    const debounceValue = useDebounce(nameQuery, 300);
    const searchItem = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setNameQuery(e.target.value);
    };
    const getListSearch = async () => {
        try {
            const res: ResponseTypes = await searchAPI.getSearchCompletion(debounceValue);
            setList(res as string[]);
        } catch (error) {
            console.log(error);
            setList([]);
        }
    };
    const getListHistories = async () => {
        try {
            const res: ResponseTypes = await searchAPI.getHistoriesSearch({
                page: 1,
                pageSize: 15,
            });
            setListHistories(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (debounceValue && debounceValue.trim().length >= 4) {
            getListSearch();
        }
        if (debounceValue.length == 0) {
            setList([]);
        }
        // getTrending();
    }, [debounceValue]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (!event.target.closest(".outside-click-listener")) {
                setList([]);
                setListHistories([]);
                setNameQuery("");
            }
        };
        document.body.addEventListener("click", handleClickOutside);

        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
    }, []);
    return (
        <div className="relative h-10 w-full outside-click-listener">
            <Input
                className="rounded-full h-10 w-full pr-16 pl-6 border-none"
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        if (nameQuery.trim().length < 4) {
                            message.error("Bạn phải nhập ít nhất 4 kí tự");
                        } else {
                            router.push(`/tim-kiem?q=${nameQuery}`);
                            setNameQuery("");
                        }
                    }
                }}
                value={nameQuery}
                onFocus={() => {
                    if (isAuth) {
                        getListHistories();
                    }
                }}
                onChange={searchItem}
                allowClear
            />
            <div
                className="absolute top-1 right-4 z-10 cursor-pointer"
                onClick={() => {
                    router.push(`/tim-kiem?q=${nameQuery}`);
                    setNameQuery("");
                }}
            >
                <BiSearch size={30} color="#f5ca80" />
            </div>
            <div className="bg-white solid w-full absolute top-11 left-0 z-[100]">
                {list.length === 0 &&
                    listHistories.length > 0 &&
                    listHistories.map((item, i) => {
                        return (
                            <SearchParamsText
                                click={() => {
                                    router.push(`/tim-kiem?q=${item}`);
                                    setList([]);
                                    setListHistories([]);
                                    setNameQuery("");
                                }}
                                styles={{ borderTopWidth: i === 0 ? "1px" : "0px" }}
                                key={item}
                            >
                                {item}
                            </SearchParamsText>
                        );
                    })}
                {list.map((item, i) => {
                    return (
                        <SearchParamsText
                            click={() => {
                                router.push(`/tim-kiem?q=${item}`);
                                setList([]);
                                setListHistories([]);
                                setNameQuery("");
                            }}
                            styles={{ borderTopWidth: i === 0 ? "1px" : "0px" }}
                            key={item}
                        >
                            {item}
                        </SearchParamsText>
                    );
                })}
            </div>
        </div>
    );
}

export default React.memo(ButtonSearch);

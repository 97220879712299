import { paths } from "./metruyenvl";
import http from "./axios_client";
import { AxiosRequestConfig } from "axios";

type Path = keyof paths;
type PathMethod<T extends Path> = keyof paths[T];

type RequestParams<P extends Path, M extends PathMethod<P>> = paths[P][M] extends {
    parameters: any;
}
    ? paths[P][M]["parameters"]
    : undefined;
type ResponseType<P extends Path, M extends PathMethod<P>> = paths[P][M] extends {
    responses: { 200: { schema: { [x: string]: any } } };
}
    ? paths[P][M]["responses"][200]["schema"]
    : undefined;

export const apiCaller = <P extends Path, M extends PathMethod<P>>(url: P, method: M, ...params: RequestParams<P, M> extends undefined ? [] : [RequestParams<P, M>]): Promise<ResponseType<P, M>> => {
    const [configParams] = params;
    const config: AxiosRequestConfig = {
        method: method as string, // Ensure 'method' is a string,
        url,
        ...configParams,
        // Add other Axios configurations as needed
    };

    return http(config)
        .then((response) => response.data)
        .catch((error) => {
            throw error.response?.data || error.message;
        });
};

import React from "react";
import Link from "next/link";

function DropdownRank({ dropMenuBgColor = "white" }: { dropMenuBgColor?: string }) {
    return (
        <div style={{ background: dropMenuBgColor }} className="pr-2 pl-2 z-30 absolute top-20 w-32 left-0 flex flex-col group-hover:visible invisible">
            <Link href={"/tac-gia"} className="text-base pt-2 pb-2  hover:text-orange-500">
                Tác giả
            </Link>
            <Link href={"/xep-hang/doc-nhieu"} className="text-base pt-2 pb-2  hover:text-orange-500">
                Đọc nhiều
            </Link>
            <Link href={"/xep-hang/luot-danh-gia"} className="text-base pt-2 pb-2  hover:text-orange-500">
                Lượt đánh giá
            </Link>
            <Link href={"/xep-hang/binh-luan"} className="text-base pt-2 pb-2  hover:text-orange-500">
                Bình luận
            </Link>
            <Link href={"/xep-hang/yeu-thich"} className="text-base pt-2 pb-2  hover:text-orange-500">
                Yêu thích
            </Link>
            <Link href={"/xep-hang/danh-gia-cao"} className="text-base pt-2 pb-2  hover:text-orange-500">
                Đánh giá cao
            </Link>
            <Link href={"/xep-hang/danh-dau"} className="text-base pt-2 pb-2  hover:text-orange-500">
                Đánh dấu
            </Link>
            <Link href={"/xep-hang/tuong-tac"} className="text-base pt-2 pb-2  hover:text-orange-500">
                Tương tác cao
            </Link>
            <Link href={"/xep-hang/de-cu"} className="text-base pt-2 pb-2  hover:text-orange-500">
                Đề cử
            </Link>
            <Link href={"/xep-hang/so-chuong"} className="text-base pt-2 pb-2  hover:text-orange-500">
                Số chương
            </Link>
        </div>
    );
}
export default DropdownRank;

"use client";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { HiBars3 } from "react-icons/hi2";
import bookAPI from "@/services/book_api";
import genreAPI from "@/services/genre_api";
import { definitions } from "@/services/metruyenvl";
import { ResponseTypes } from "@/types/constants";

interface IPropTypes {
    type?: 1 | 2;
}

function DropdownHeader({ dropMenuBgColor = "white" }: { dropMenuBgColor?: string }) {
    const [listCategory, setListCategory] = useState<definitions["do.TagDO"][] | undefined>([]);
    useEffect(() => {
        const getData = async () => {
            try {
                const res = await genreAPI.getAllGenres("category");
                setListCategory(res.data as definitions["do.TagDO"][]);
                if (typeof window !== "undefined") {
                    localStorage.setItem("category", JSON.stringify(res));
                }
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);

    return (
        <div className="md:flex hidden group items-center gap-1 cursor-pointer hover:bg-white pr-3 pl-3 h-20 relative lg:text-sm text-xs">
            <HiBars3 size={24} />
            <div> Thể loại</div>
            <div className="pr-2 bg-white pl-2 z-30 w-80 absolute top-20 left-0 grid grid-cols-2 group-hover:visible invisible">
                {listCategory?.map((item) => {
                    return (
                        <Link key={item.id} rel="noopener noreferrer" className="text-base pt-2 pb-2  hover:text-orange-500" href={`/the-loai/${item.slug}/${item.id}`}>
                            {item.name}
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}

export default DropdownHeader;

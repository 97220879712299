import { definitions } from "@/services/metruyenvl";
import Image from "next/image";
import { AiOutlineHeart } from "react-icons/ai";

export const NormalStyles = ({ children, styles }: { children: React.ReactNode; styles?: React.CSSProperties }) => {
    return (
        <div className="line-clamp-1 text-gray-500 text-xs xl:text-sm" style={styles}>
            {children}{" "}
        </div>
    );
};

export const NormalStylesWithClick = ({ children, styles, click }: { children: React.ReactNode; styles?: React.CSSProperties; click?: () => void }) => {
    return (
        <div className="line-clamp-1 text-gray-500 text-xs md:text-sm cursor-pointer hover:text-orange-400" style={styles} onClick={click}>
            {children}{" "}
        </div>
    );
};

export const NormalTextUppercase = ({ children, styles }: { children: React.ReactNode; styles?: React.CSSProperties }) => {
    return (
        <div style={styles} className="line-clamp-1 text-base uppercase">
            {children}
        </div>
    );
};

export const TitleCard = ({ children, styles, click }: { children: React.ReactNode; styles?: React.CSSProperties; class?: string; click?: () => void }) => {
    return (
        <p className="line-clamp-1 xl:text-sm text-xs font-semibold cursor-pointer hover:text-orange-400" style={styles} onClick={click}>
            {children}
        </p>
    );
};

export const TitleCardWithoutClick = ({ children, styles }: { children: React.ReactNode; styles?: React.CSSProperties; class?: string }) => {
    return (
        <p className="line-clamp-1 text-sm font-semibold cursor-pointer" style={styles}>
            {children}
        </p>
    );
};

export const DesCard = ({ children, styles }: { children: React.ReactNode; styles?: React.CSSProperties }) => {
    return (
        <div style={styles} className="line-clamp-2 xl:text-xs text-[10px] text-gray-500">
            {children}
        </div>
    );
};

export const GenreCard = ({ children, styles, click }: { children: React.ReactNode; styles?: React.CSSProperties; click?: () => void }) => {
    return (
        <div onClick={click} style={styles} className="p-1 cursor-pointer border border-solid border-amber-400 text-amber-400 text-[10px] font-semibold line-clamp-1 xl:text-sm">
            {children}
        </div>
    );
};

export const SwitchEmoji = ({ styles, type }: { styles?: React.CSSProperties; type: string }) => {
    const iconSize: number = 50;
    const handleShow = () => {
        switch (type) {
            case "love":
                return <Image unoptimized={true} width={iconSize} height={iconSize} className="reactButton ease-in duration-75 hover:scale-150" src="/love.gif" alt="Love emoji" />;
            case "like":
                return <Image unoptimized={true} width={iconSize} height={iconSize} className="reactButton ease-in duration-75 hover:scale-150" src="/like.gif" alt="Like emoji" />;
            case "lol":
                return <Image unoptimized={true} width={iconSize} height={iconSize} className="reactButton ease-in duration-75 hover:scale-150" src="/haha.gif" alt="Haha emoji" />;
            case "sad":
                return <Image unoptimized={true} width={iconSize} height={iconSize} className="reactButton ease-in duration-75 hover:scale-150" src="/sad.gif" alt="Sad emoji" />;
            case "wow":
                return <Image unoptimized={true} width={iconSize} height={iconSize} className="reactButton ease-in duration-75 hover:scale-150" src="/wow.gif" alt="Wow emoji" />;
            case "angry":
                return <Image unoptimized={true} width={iconSize} height={iconSize} className="reactButton ease-in duration-75 hover:scale-150" src="/angry.gif" alt="Angry emoji" />;
            default:
                return <Image unoptimized={true} width={iconSize} height={iconSize} className="reactButton ease-in duration-75 hover:scale-150" src="/like.gif" alt="Like emoji" />;
        }
    };
    return <div>{handleShow()}</div>;
};

export const ShowMore = ({ children, styles, click }: { children: React.ReactNode; styles?: React.CSSProperties; click?: () => void }) => {
    return (
        <div style={styles} onClick={click} className="text-xs cursor-pointer hover:text-orange-400 font-semibold">
            {children}
        </div>
    );
};

export const HeadModule = ({ children, styles }: { children: React.ReactNode; styles?: React.CSSProperties }) => {
    return (
        <div style={styles} className="mb-6 text-black font-semibold xl:text-lg line-clamp-1">
            {children}
        </div>
    );
};

export const MainStyles = ({ children, styles }: { children: React.ReactNode; styles?: React.CSSProperties }) => {
    return (
        <div style={styles} className="w-full xl:pr-60 xl:pl-60 md:pr-20 md:pl-20 2xl:pr-72 2xl:pl-72">
            {children}
        </div>
    );
};

export const HiddenTagForSEO = ({ h1, h2, h3, description }: { h1?: string; h2?: string; h3?: string; description?: string }) => {
    return (
        <div className="hidden">
            <h1>{h1}</h1>
            <h2>{h2}</h2>
            <h3>{h3}</h3>
            <div>{description}</div>
        </div>
    );
};

export const ListBooksUpdateLatestHiddenSEO = ({ title, books }: { title: string; books: definitions["do.BookUpdateLatestRes"][] }) => {
    return (
        <div className="hidden">
            {books &&
                Array.isArray(books) &&
                books.map((book) => {
                    return (
                        <div key={book.slug}>
                            <h1>title</h1>
                            <a href={`/truyen/${book.slug}`}>
                                <h2>Tên truyện: {book.name}</h2>
                            </a>
                            <a href={`/tac-gia/${book.author_slug}`}>
                                <h2>Tác giả: {book.author}</h2>
                            </a>
                            <a href={`/truyen/${book.slug}/${book.chapter_slug}`}>
                                <h3>Chương mới: {book.chapter_name}</h3>
                            </a>
                            <a href={`/the-loai/${book.tag_id}`}>
                                <h3>Thể loại: {book.tag}</h3>
                            </a>
                            <a href={`/ho-so/thong-tin/${book.publisher_id}`}>
                                <h3>Người đăng: {book.publisher}</h3>
                            </a>
                        </div>
                    );
                })}
        </div>
    );
};

export const SearchParamsText = ({ children, styles, click }: { children: React.ReactNode; styles?: React.CSSProperties; click?: () => void }) => {
    return (
        <div style={{ borderWidth: "0px 1px 1px 1px", ...styles }} onClick={click} className="text-gray-500 text-lg line-clamp-1 p-4 border-gray-400 cursor-pointer">
            <div className="line-clamp-1"> {children}</div>
        </div>
    );
};

import http from "./axios_client";
import { apiCaller } from "./metruyenvl_caller";

const bookmarkAPI = {
    async getAllByUser(userID: string, offset: number, limit: number) {
        return apiCaller("/bookmark", "get", { query: { user_id: userID, offset: offset, limit: limit } });
    },

    async getAllByBook(bookID: string, offset: number, limit: number) {
        return apiCaller("/bookmark", "get", { query: { book_id: bookID, offset: offset, limit: limit } });
    },

    async create({ bookID, chapterID }: { bookID: string; chapterID?: string }) {
        return apiCaller("/bookmark", "post", {
            body: {
                request: {
                    book_id: bookID,
                    chapter_id: chapterID as string,
                },
            },
            header: {
                Authorization: "",
            },
        });
    },

    async delete(bookID: string) {
        return apiCaller("/bookmark/{book_id}", "delete", {
            path: { book_id: bookID },
            header: {
                Authorization: "",
            },
        });
    },
};

export default bookmarkAPI;

"use client";
import React, { useEffect, useState } from "react";

function useScroll() {
    const [scrolling, setScrolling] = useState<boolean>(false);
    const [scrollTop, setScrollTop] = useState<number>(0);
    useEffect(() => {
        function onScroll() {
            let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
            if (currentPosition > scrollTop) {
                // downscroll code
                setScrolling(false);
            } else {
                // upscroll code
                setScrolling(true);
            }
            setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);
    return { scrolling, scrollTop };
}

export default useScroll;

import React from "react";
import { FaUser } from "react-icons/fa6";
import { useRouter } from "next/navigation";
import { Avatar } from "antd";
import { useAuth } from "@/hooks";
import { FcLike } from "react-icons/fc";
import CollapseCategory from "@/layouts/header/components/collapse-category";
import { GoCommentDiscussion } from "react-icons/go";
import { IoMdExit } from "react-icons/io";
import { IoNotificationsSharp } from "react-icons/io5";
import Image from "next/image";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { BsFillBookmarkCheckFill } from "react-icons/bs";
import { userInfo } from "../redux/reducers/user.reducer";

function DrawerUser() {
    const { logout, profile } = useAuth();
    const userReducer = useSelector((state: RootState) => state.userReducer);
    const router = useRouter();
    return (
        <div>
            <div className="flex items-center gap-x-4 p-3 border-b border-solid border-slate-200 ">
                <Avatar size={50} icon={<Image unoptimized={true} height={50} width={50} src={profile?.avatar ?? "/avatar.gif"} alt="" />} />
                <div>
                    <div className="font-semibold text-base line-clamp-1">{userInfo?.name}</div>
                    <div className="flex items-center gap-x-2">
                        <FcLike />
                        <GoCommentDiscussion />
                    </div>
                </div>
            </div>
            <div
                onClick={() => {
                    router.push(`/ho-so/thong-tin/${profile.id}`);
                }}
                className="flex items-center gap-x-4 p-3 border-b border-solid border-slate-200 "
            >
                <FaUser size={23} color="#b78a28" /> <p className="font-semibold text-base"> Hồ sơ</p>
            </div>
            {/* <div className="flex items-center gap-x-4 p-3 border-b border-solid border-slate-200 ">
                <IoSettings size={23} color="#b78a28" /> <p className="font-semibold text-base"> Cài đặt</p>
            </div> */}
            <div className="flex items-center justify-between gap-x-4 p-3 border-b border-solid border-slate-200 ">
                <div className="flex items-center gap-x-4">
                    {" "}
                    <IoNotificationsSharp size={23} color="#b78a28" /> <p className="font-semibold text-base"> Thông báo</p>
                </div>
                <div className="bg-[#b78a28] rounded-full text-xs flex items-center justify-center p-1 text-white font-medium">{userReducer?.listNotification?.length ?? 0}</div>
            </div>
            <div
                onClick={() => {
                    router.push(`/ho-so/tu-truyen/${profile.id}`);
                }}
                className="flex items-center gap-x-4 p-3 border-b border-solid border-slate-200 "
            >
                <BsFillBookmarkCheckFill size={23} color="#b78a28" /> <p className="font-semibold text-base"> Đã đọc</p>
            </div>
            <div
                className="flex items-center gap-x-4 p-3 border-b border-solid border-slate-200 "
                onClick={() => {
                    logout();
                }}
            >
                <IoMdExit size={23} color="#b78a28" /> <p className="font-semibold text-base">Thoát</p>
            </div>
            <CollapseCategory />
        </div>
    );
}

export default DrawerUser;

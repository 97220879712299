import { apiCaller } from "./metruyenvl_caller";

const readAPI = {
    async getAllByUser(userID: string, offset: number, limit: number) {
        return apiCaller("/reading", "get", { query: { user_id: userID, offset, limit } });
    },

    async createRead(bookID: string, chapterID: string) {
        return apiCaller("/reading", "post", {
            body: { request: { book_id: bookID, chapter_id: chapterID } },
            header: {
                Authorization: "",
            },
        });
    },

    async getAllUserReading({ bookID, chapterID, offset, limit }: { bookID: string; chapterID?: string; offset: number; limit: number }) {
        return apiCaller("/reading/user", "get", { query: { book_id: bookID, chapter_id: chapterID } });
    },

    async delete(bookID: string) {
        return apiCaller("/reading/{book_id}", "delete", {
            path: { book_id: bookID },
            header: {
                Authorization: "",
            },
        });
    },
};

export default readAPI;

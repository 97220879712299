import { IPassword } from "@/types/constants";
import { apiCaller } from "./metruyenvl_caller";
import { definitions } from "./metruyenvl";

const authAPI = {
    async signUp(email: string, password: string) {
        return apiCaller("/auth/register", "post", {
            body: {
                request: {
                    email: email,
                    password: password,
                }
            }
        })
    },
    async signIn(email: string, password: string) {
        return apiCaller("/auth/login", "post", {
            body: {
                request: {
                    email: email,
                    password: password,
                }
            }
        });
    },
    async logout() {
        return apiCaller("/auth/logout", "delete", {
            header: {
                Authorization: ``,
            }
        });
    },

    async getMe() {
        return apiCaller("/auth/me", "get", { header: { Authorization: `` } })
    },

    async update(user: definitions["do.UserDO"]) {
        return apiCaller("/user", "post", {
            header: {
                Authorization: "",
            },
            body: {
                request: {
                    avatar: user.avatar,
                    bio: user.bio,
                    birth_time: user.birth_time,
                    name: user.name,
                    sex: user.sex,
                    status: user.status,
                }
            }
        })
    },

    async forgotPassword(email: string) {
        return apiCaller("/auth/request", "post", {
            body: {
                request: {
                    email: email,
                }
            }
        })
    },

    async changePassword(data: IPassword) {
        return apiCaller("/auth/password", "post", {
            header: {
                Authorization: ``,
            },
            body: {
                request: data
            }
        })
    },

    async recoveryPassword(data: IPassword) {
        return apiCaller("/auth/recovery", "post", {
            body: {
                request: data
            }
        })
    },

    async loginSocial(provider: string) {
        return apiCaller("/auth/login/{provider}", "get", { path: { provider: provider } });
    },

    async refreshToken(refreshToken: string) {
        return apiCaller("/auth/refresh", "post", {
            header: {
                Authorization: `Bearer ${refreshToken}`,
            }
        })
    },

    async getBanner() {
        return apiCaller("/config/banners", "get");
    },

    async completeLogin(provider: string) {
        return apiCaller("/auth/login/{provider}/callback", "get", { path: { provider: provider } });
    },

    async swap3rdToken(provider: string, certToken: string) {
        return apiCaller("/auth/login/{provider}/swap", "get", { header: { Authorization: `Bearer ${certToken}` }, path: { provider: provider } }) as any;
    },
};

export default authAPI;

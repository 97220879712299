"use client";
import React, { useState, useEffect } from "react";
import Link from "next/link";
import { getNotification, userInfo } from "@/redux/reducers/user.reducer";
import { IoNotificationsSharp } from "react-icons/io5";
import type { MenuProps } from "antd";
import { TitleCard, NormalStyles } from "@/layouts/styles/ssr.style";
import { Button, Dropdown, Space, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/navigation";
import { definitions } from "@/services/metruyenvl";
import { AppDispatch, RootState } from "@/redux/store";

function DropdownNotification() {
    const dispatch = useDispatch<AppDispatch>();
    const userInfoData = useSelector(userInfo);
    useEffect(() => {
        dispatch(getNotification({ page: 1, pageSize: 10 }));
    }, []);

    const fakerNoti = [
        {
            title: "Bạn có một thông báo",
            time: "10 phút trước",
            image: "https://assets.mycast.io/posters/naturo-fan-casting-poster-46164-large.jpg?1594315169",
        },
        {
            title: "Bạn có một thông báo",
            time: "10 phút trước",
            image: "https://assets.mycast.io/posters/naturo-fan-casting-poster-46164-large.jpg?1594315169",
        },
        {
            title: "Bạn có một thông báo",
            time: "10 phút trước",
            image: "https://assets.mycast.io/posters/naturo-fan-casting-poster-46164-large.jpg?1594315169",
        },
    ];

    const convertItem = (): MenuProps["items"] => {
        let items: MenuProps["items"] = fakerNoti?.map((item: any, index: number) => {
            return {
                label: (
                    <div className="flex justify-between w-96" key={index}>
                        {/* <SImage src={item.image} alt="" />
                            <div>
                                <TitleCard>{item.title}</TitleCard>
                                <NormalStyles styles={{ marginTop: "8px" }}>
                                {item.time}
                                </NormalStyles>
                            </div> */}
                        <TitleCard>{item.description}</TitleCard>
                    </div>
                ),
                key: index,
            };
        });
        return items;
    };
    const items: MenuProps["items"] = convertItem();

    return (
        <Dropdown menu={{ items }} placement="bottom" overlayStyle={{ top: "80px" }} trigger={["click"]}>
            <div className="h-20 hover:bg-white pr-3 pl-3 cursor-pointer flex items-center">
                <IoNotificationsSharp size={24} />
            </div>
        </Dropdown>
    );
}

export default DropdownNotification;

import { apiCaller } from "./metruyenvl_caller";

const genreAPI = {
    async getAllGenres(type?: "category" | "tag") {
        return apiCaller("/tag/all", "get", { query: { type } });
    },

    async getGenre({ id = 0, slug = "" }: { id?: number; slug?: string }) {
        return apiCaller("/tag", "get", { query: { id: id, slug: slug } });
    },

    async getAllBooks({ id = 0, slug = "", withStats = false, page, pageSize }: { id?: any; slug?: string; withStats?: boolean; page: number; pageSize: number }) {
        return apiCaller("/tag/books", "get", { query: { id: id, slug: slug, with_stats: withStats, page: page, page_size: pageSize } });
    },
};

export default genreAPI;

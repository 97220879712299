import { definitions } from "./metruyenvl";
import { apiCaller } from "./metruyenvl_caller";

export interface ISetting {
    chapter_page_size: number, // 51
    book_page_size: number, // 20
    language: string,
    theme?: string,
    font_family?: string,
    font_size?: number; // 14px
    content_view_size?: number; // 1000px
    line_padding?: number; // 140%
    color?: string;
    space_column?: number;
    bg_color?: string;
    content_color?: string;
    save_offline?: true;
}


const userSettingApi = {
    async getSetting() {
        return apiCaller("/user/settings", "get", {
            header: {
                Authorization: "",
            },
        });
    },
    async postSetting(userSettings: definitions["do.UserSettingsDO"]) {
        return apiCaller("/user/settings", "post", {
            header: {
                Authorization: "",
            },
            body: {
                request: userSettings
            },
        });
    },
};

export default userSettingApi;

import { apiCaller } from "./metruyenvl_caller";

const searchAPI = {
    async getSearch(q: string, page: number, pageSize: number) {
        return apiCaller("/search", "get", {
            query: { q: q, page: page, page_size: pageSize },
            header: {
                Authorization: "Bearer",
            },
        });
    },
    async getSearchCompletion(q: string) {
        return apiCaller("/search/completion", "get", {
            query: {
                q,
            },
        });
    },
    async getTrending() {
        return apiCaller("/search/trending", "get");
    },
    async getHistoriesSearch({ page, pageSize }: { page: number; pageSize: number }) {
        return apiCaller("/search/history", "get", {
            header: {
                Authorization: "",
            },
            query: {
                page: page,
                page_size: pageSize,
            },
        });
    },
    async deleteHistorySearch(q: string) {
        return apiCaller("/search/history", "delete", {
            header: {
                Authorization: "",
            },
            body: {
                request: {
                    query: q,
                },
            },
        });
    },
};

export default searchAPI;

"use client";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import { Avatar, Dropdown, MenuProps, Space } from "antd";
import authAPI from "@/services/auth_api";
import { useAuth } from "@/hooks";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/redux/store";
import { setUser, userInfo } from "@/redux/reducers/user.reducer";

function Account() {
    const { logout } = useAuth();
    const dispatch = useDispatch<AppDispatch>();
    // const [infor, setInfor] = useState<definitions["do.AuthorDO"]>();
    const user = useSelector(userInfo);
    useEffect(() => {
        const getData = async () => {
            try {
                const res = await authAPI.getMe();
                localStorage.setItem("user_info", JSON.stringify(res.data));
                // setInfor(res.data);
                dispatch(setUser(res.data));
            } catch (error) {
                console.log(error);
            }
        };
        getData();
    }, []);
    const items: MenuProps["items"] = [
        {
            key: "1",
            label: (
                <Link rel="noopener noreferrer" className="text-base font-semibold" href={`/ho-so/thong-tin/${user?.info.id}`}>
                    Hồ sơ
                </Link>
            ),
        },
        {
            key: "2",
            label: (
                <Link
                    rel="noopener noreferrer"
                    className="text-base font-semibold"
                    href=""
                    onClick={() => {
                        logout();
                    }}
                >
                    Đăng xuất
                </Link>
            ),
        },
    ];
    return (
        <Dropdown menu={{ items }} placement="bottom" overlayStyle={{ top: "80px" }} className="md:flex items-center gap-1 cursor-pointer hover:bg-white pr-3 pl-3 h-20 hidden">
            <div className="flex">
                <Avatar
                    size="default"
                    icon={
                        // <img src="https://play-lh.googleusercontent.com/mVrfIXxFLMO7hb3IrY4YRwKRJyXAaJ3X7ST3Qs2VkCQezDRxx1AMCtTCVAYK5hHHKqQ" alt="" />
                        <Image unoptimized={true} src={user?.info.avatar ?? "/avatar.gif"} height={30} width={30} alt="avatar-user" />
                    }
                />
                <div> {user?.info.name}</div>
            </div>
        </Dropdown>
    );
}

export default Account;

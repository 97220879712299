import http from "./axios_client";
import { apiCaller } from "./metruyenvl_caller";

const coinAPI = {
    async getCoin() {
        return apiCaller("/coin", "get", {
            header: {
                Authorization: "",
            },
        });
    },
    async postOrder() {
        return apiCaller("/coin/order", "post", {
            header: {
                Authorization: "",
            },
            body: {
                request: {
                    coin: 0,
                    description: undefined,
                    title: "",
                },
            },
        });
    },
    async getListOrder(page?: number, pageSize?: number) {
        return apiCaller("/coin/orders", "get", {
            header: {
                Authorization: "",
            },
            query: {
                page: page,
                page_size: pageSize,
            },
        });
    },
};

export default coinAPI;

import React from "react";

export interface IModalUser {
    type: boolean;
    visible?: boolean;
    onCancel?: () => void;
    changeType?: (value: boolean | undefined) => void;
}

export interface ResponseTypes {
    message?: string;
    data?: any;
    status?: number;
    encryption?: string;
    paging?: {
        current_page?: number;
        limit?: number;
        next_offset?: number;
        next_page?: number;
        page_size?: number;
        total?: number;
        total_pages?: number;
    };
}


export type IDetailStory = {
    title?: string;
    key?: string;
    id?: string;
    thumbnail?: string;
    is_like?: boolean;
    book_id?: string;
    status?: boolean;
    chapter_index?: string;
    introduce?: any;
    description?: string;
    chapter?: number;
    is_hold?: boolean;
    author?: string;
    category?: string;
    image?: string;
    comments?: number;
    votes?: number;
    name?: string;
    time?: string;
    reads?: number;
    chapName?: string;
    quantityRead?: number;
    rating?: number;
    author_name?: string;
    genres?: any;
    updated_at?: any;
    last_chapter?: any;
    reacts?: number;
    score?: number;
    slug?: string;
    avatarUrl?: string;
    holds?: number;
    reviews?: number;
    reading_index?: any;
    reading_slug?: string;
};

export type ISeo = {
    description?: string;
    title?: string;
    image?: string;
};

export type ITableTypes = {
    title: string;
    dataIndex: string;
    render?: () => React.ReactNode;
    key: string;
};

export type ILike = {
    book_id?: string;
    likeable_id?: string;
    react_type?: number;
};

export type IPassword = {
    new_password?: string;
    password?: string;
    token?: string;
};

export type IAuthor = {
    id?: string;
    name?: string;
    slug?: string;
    mangas_count?: number;
    reads_count?: number;
};

export type IHeaders = {
    token?: string;
};

export enum Constants {
    DefaultUserName = `Hắc Bạch Vô Thường`, 
    DefaultHeaderLogo = `/default-header-logo.webp`,
    DefaultBookThumbnail = `https://p21-ad-sg.ibyteimg.com/obj/ad-site-i18n-sg/202403045d0d697e1b14dacc4f748d39`
}
import { configureStore, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { definitions } from "@/services/metruyenvl";
import bookmarkAPI from "@/services/bookmark_api";
import readAPI from "@/services/read_api";
import coinAPI from "@/services/coin_api";
import notificationApi from "@/services/notification_api";
import userSettingApi, { ISetting } from "@/services/setting_api";

const localStorageKey = "user";

const getInitialState = () => {
    const storedState = localStorage.getItem(localStorageKey);
    return storedState ? JSON.parse(storedState) : initialState;
};

// Read
export const getAllBooksReading = createAsyncThunk("user/readings", async ({ userID, offset, limit }: { userID: string; offset: number; limit: number }) => {
    return await readAPI.getAllByUser(userID, offset, limit);
});

// Bookmark
export const getAllBooksRead = createAsyncThunk("user/bookmarked", async ({ userID, offset, limit }: { userID: string; offset: number; limit: number }) => {
    return await bookmarkAPI.getAllByUser(userID, offset, limit);
});

export const getCoinUser = createAsyncThunk("user/coin", async () => {
    return await coinAPI.getCoin();
});

export const getListOrderCoin = createAsyncThunk("user/order/list", async ({ page, pageSize }: { page: number; pageSize: number }) => {
    return await coinAPI.getListOrder(page, pageSize);
});

export const orderCoin = createAsyncThunk("user/coin/order", async () => {
    return await coinAPI.postOrder();
});

export const getNotification = createAsyncThunk("user/notification", async ({ page, pageSize }: { page: number; pageSize: number }) => {
    return await notificationApi.getNotification(page, pageSize);
});

export const getSettingUser = createAsyncThunk("/user/setting", async () => {
    return await userSettingApi.getSetting();
});

export const postSettingUser = createAsyncThunk("/user/setting/save", async (userSettings: definitions["do.UserSettingsDO"]) => {
    return await userSettingApi.postSetting(userSettings);
});

const initialState = {
    isLoading: false,
    info: {} as definitions["do.UserDO"],
    isShowForm: false as boolean,
    coinUser: {} as definitions["models.UserCoinMD"],
    listNotification: [] as any,
    listOrderHistories: [] as any,
    listBookmarks: [] as definitions["do.BookmarksByRes"], // Danh sách user đã thực hiện bookmarked
    isLoadFullBookmarks: false,
    listReads: [] as definitions["do.BookDO"][], // Danh sách user đã đọc
    isLoadFullRead: false,
    listReading: [] as definitions["do.BookDO"][], // Danh sách user đang đọc hiện tại
    isLoadFullReading: false,
    settings: {
        comment_review_page_size: 10,
        chapter_page_size: 51,
        book_page_size: 20,
        language: "vi",
        theme: "pink",
        font_size: 16, // px
        content_view_size: 1000, // px
        line_padding: 140, // %
        space_column: 0,
        save_offline: true,
        color: "#E1F0DA",
        font_family: "Roboto",
        content_color: "#D4E7C5",
        background_color: "#E1F0DA",
    } as definitions["do.UserSettingsDO"],
};

export const initialUserState = () => {
    if (typeof window !== "undefined") {
        const storedState = localStorage.getItem(localStorageKey);
        return storedState ? JSON.parse(storedState) : initialState;
    }

    return initialState;
};

const userSettingsKey = "user_data_settings"
const userSlice = createSlice({
    initialState,
    name: "user",
    reducers: {
        setUser: (state, action) => {
            state.info = action.payload;
        },
        showModalForm: (state, action) => {
            state.isShowForm = action.payload;
        },
        initialUserStateFromStorage: (state) => { // Using slug
            const defaultSettings = {
                font_size: state.settings.font_size ?? 16,
                color: state.settings.color ?? "#E1F0DA",
                font_family: state.settings.font_family ?? "Roboto",
                space_column: state.settings.space_column ?? 0,
                content_color: state.settings.content_color ?? "#D4E7C5",
                background_color: state.settings.background_color ?? "#E1F0DA",
                comment_review_page_size: 10,
                chapter_page_size: 51,
                book_page_size: 20,
                language: "vi",
                theme: "pink",
                content_view_size: 1000, // px
                line_padding: 140, // %
                save_offline: true,
            } as definitions["do.UserSettingsDO"];

            const storedState = localStorage.getItem(userSettingsKey);
            const newState = storedState ? JSON.parse(storedState) : defaultSettings;

            state.settings = newState || defaultSettings
        },
        setUserStateToStorage: (state) => {
            const userSettings = state.settings
            localStorage.setItem(userSettingsKey, JSON.stringify(userSettings))
        },
    },
    extraReducers(builder) {
        builder.addCase(getAllBooksRead.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllBooksRead.fulfilled, (state, action: any) => {
            state.isLoading = false;
            state.listBookmarks = JSON.parse(action.payload.data ?? "{}");
        });
        builder.addCase(getAllBooksRead.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getCoinUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCoinUser.fulfilled, (state, action: any) => {
            state.isLoading = false;
            state.coinUser = JSON.parse(action.payload.data ?? "{}");
        });
        builder.addCase(getCoinUser.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getListOrderCoin.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListOrderCoin.fulfilled, (state, action: any) => {
            state.isLoading = false;
            state.listOrderHistories = JSON.parse(action.payload.data ?? "[]");
        });
        builder.addCase(getListOrderCoin.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getAllBooksReading.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAllBooksReading.fulfilled, (state, action: any) => {
            state.isLoading = false;
            state.listReading = JSON.parse(action.payload.data ?? "{}");
        });
        builder.addCase(getAllBooksReading.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getNotification.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getNotification.fulfilled, (state, action: any) => {
            state.isLoading = false;
            state.listNotification = action.payload;
        });
        builder.addCase(getNotification.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getSettingUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSettingUser.fulfilled, (state, action: any) => {
            state.settings = {
                font_size: state.settings.font_size ?? 16,
                color: state.settings.color ?? "#E1F0DA",
                font_family: state.settings.font_family ?? "Roboto",
                space_column: state.settings.space_column ?? 0,
                content_color: state.settings.content_color ?? "#D4E7C5",
                background_color: state.settings.background_color ?? "#E1F0DA",
                comment_review_page_size: 10,
                chapter_page_size: 51,
                book_page_size: 20,
                language: "vi",
                theme: "pink",
                content_view_size: 1000, // px
                line_padding: 140, // %
                save_offline: true,
            };
        });
        builder.addCase(getSettingUser.rejected, (state, action) => {
            state.isLoading = false;
        });
    },
});

export const { setUser, showModalForm, initialUserStateFromStorage, setUserStateToStorage } = userSlice.actions;

export const userInfo = (state: RootState) => state.userReducer;

export default userSlice.reducer;
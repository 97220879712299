import React, { memo, useState } from "react";
import { Button, Drawer } from "antd";
import { MdOutlineClose } from "react-icons/md";
import { useAuth } from "@/hooks";
import { HiMiniBookOpen } from "react-icons/hi2";
import DrawerUser from "@/components/drawer-user";
import { BsFillBookmarkCheckFill } from "react-icons/bs";
import { FaUserPen, FaUser } from "react-icons/fa6";
import CollapseCategory from "./collapse-category";

interface IPropTypes {
    visible?: boolean;
    close?: () => void;
    showForm: (e: boolean) => void;
}

function DrawerRight(props: IPropTypes) {
    const { isAuth } = useAuth();
    const { visible, close, showForm } = props;
    const [open, setOpen] = useState(visible);
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Drawer placement="right" closeIcon={false} onClose={close} open={visible} width={"70%"} className="md:hidden block relative pt-0 ">
            <MdOutlineClose className="absolute top-2 right-2" size={26} onClick={close} />
            <div className="h-10"></div>

            {isAuth === true ? (
                <DrawerUser />
            ) : (
                <div>
                    {" "}
                    <div
                        onClick={() => {
                            showForm(false);
                        }}
                        className="flex items-center gap-x-4 p-3 border-b border-solid border-slate-200 "
                    >
                        <FaUser size={23} color="#b78a28" /> <p className="font-semibold text-base"> Đăng nhập</p>
                    </div>
                    <div
                        onClick={() => {
                            showForm(true);
                        }}
                        className="flex items-center gap-x-4 p-3 border-b border-solid border-slate-200 "
                    >
                        <FaUserPen size={23} color="#b78a28" /> <p className="font-semibold text-base"> Đăng ký</p>
                    </div>
                    {/* <div className="flex items-center gap-x-4 p-3 border-b border-solid border-slate-200 ">
                        <HiMiniBookOpen size={23} />{" "}
                        <p className="font-semibold text-base"> Thể loại</p>
                    </div> */}
                    <CollapseCategory />
                </div>
            )}
        </Drawer>
    );
}

export default memo(DrawerRight);

"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import Account from "./components/account";
import DropdownHeader from "./components/dropdown-header";
import DrawerRight from "./components/drawer-right";
import DropdownNotification from "./components/dropdown-notication";
import { HiBars3 } from "react-icons/hi2";
import DropdownRank from "./components/dropdown-rank";
import { useRouter } from "next/navigation";
import ButtonSearch from "@/components/button-search";
import AuthForm from "@/components/auth-form";
import ScrollToTop from "react-scroll-to-top";
import { FaArrowUp } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/redux/store";
import { getSettingUser, initialUserStateFromStorage, setUserStateToStorage, showModalForm } from "@/redux/reducers/user.reducer";
import { setTheme, themeColor } from "@/redux/reducers/theme.reducer";
import { useAuth } from "@/hooks";
import { Constants, ResponseTypes } from "@/types/constants";
import { definitions } from "@/services/metruyenvl";
import useDeepCompareEffect, { useDeepCompareMemoize } from "use-deep-compare-effect";
import { firebaseCloudMessaging } from "@/utils/firebase";

// background: #faf5e8;

function Header({ token, headerBgColor = "#F8E8EE" }: { token?: string; headerBgColor?: string }) {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const router = useRouter();
    const { isAuth } = useAuth();
    const customTheme = useSelector(themeColor);
    const userData = useSelector((state: RootState) => state.userReducer);

    const dispatch = useDispatch<AppDispatch>();
    const [isType, setIsType] = useState<boolean>(true);

    const [fcmToken, setFcmToken] = useState<string | undefined>(undefined);

    const getToken = async () => {
        try {
            const token = await firebaseCloudMessaging.init();
            if (token) {
                await firebaseCloudMessaging.getMessage();

                console.log("getToken called - Token: ", token);
                setFcmToken(token);
            }
        } catch (error) {
            console.log(error);
        }

        console.log("getToken called done");
    };

    useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.addEventListener("message", (event) => console.log("event for the service worker", event));
        }
        async function setToken() {
            await getToken();
        }
        setToken();
    }, []);

    const openVisible = () => {
        setIsVisible(true);
    };

    const closeVisible = () => {
        setIsVisible(false);
    };

    const checkThemeUserLogin = async () => {
        try {
            const res = await dispatch(getSettingUser());
            const payload = res.payload as ResponseTypes;
            const data = payload.data as definitions["do.UserSettingsDO"];
            if (!!data) {
                dispatch(
                    setTheme({
                        bgColor: data.background_color ?? "#E1F0DA",
                        contentColor: data.content_color ?? "#D4E7C5",
                    })
                );
            } else {
                dispatch(
                    setTheme({
                        bgColor: "#E1F0DA",
                        contentColor: "#D4E7C5",
                    })
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    useDeepCompareEffect(() => {
        if (userData && userData.settings) {
            dispatch(setUserStateToStorage());
        }

        if (!userData || !userData.settings) {
            dispatch(initialUserStateFromStorage());
        }
    }, [userData.settings]);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        });
    }, []);

    useEffect(() => {
        if (isAuth) {
            checkThemeUserLogin();
        } else {
            let customTheme: any = JSON.parse(localStorage.getItem("themeChapter") ?? "{}");
            if (customTheme.contentColor) {
                dispatch(setTheme(customTheme));
            } else {
                dispatch(
                    setTheme({
                        bgColor: "#E1F0DA",
                        contentColor: "#D4E7C5",
                    })
                );
            }
        }
    }, []);

    return (
        <div>
            <ScrollToTop
                style={{ transition: "3s" }}
                smooth
                component={
                    <div className="flex items-center justify-center h-full w-full rounded-lg">
                        <FaArrowUp />
                    </div>
                }
                // component={<BsFillArrowUpSquareFill size={40} />}
            />

            <div style={{ background: customTheme.contentColor !== "" ? customTheme.contentColor : headerBgColor }} className="md:grid flex md:grid-cols-3 md:gap-7 lg:p-0 p-4 lg:pl-4 md:pt-0 lg:pr-[5%] justify-between md:justify-center text-sm w-full h-12 md:h-20 items-center">
                <div className="flex items-center justify-end text-slate-500 lg:mr-6">
                    <div
                        onClick={() => {
                            router.push("/");
                        }}
                    >
                        <Image unoptimized={true} src={Constants.DefaultHeaderLogo} alt="" width={75} height={75} className="md:mr-8 cursor-pointer" />
                    </div>
                    <DropdownHeader />
                    <div className="hover:bg-white group relative md:flex hidden items-center lg:text-sm text-xs cursor-pointer h-20 pr-3 pl-3 ">
                        Xếp hạng
                        <DropdownRank />
                    </div>
                </div>
                <div className="md:block md:w-full w-2/3">
                    <ButtonSearch />
                </div>
                <div className="md:hidden block" onClick={openVisible}>
                    <HiBars3 size={30} color="#ebc77f" />
                </div>
                <div className="md:flex hidden gap-7 text-slate-500 items-center ml-8">
                    {isAuth ? (
                        <DropdownNotification />
                    ) : (
                        <div
                            className="cursor-pointer hover:text-black lg:text-sm text-xs"
                            onClick={() => {
                                setIsType(false);
                                dispatch(showModalForm(true));
                            }}
                        >
                            Đăng nhập
                        </div>
                    )}
                    {isAuth ? (
                        <Account />
                    ) : (
                        <div
                            className="cursor-pointer hover:text-black lg:text-sm text-xs"
                            onClick={() => {
                                setIsType(true);
                                dispatch(showModalForm(true));
                            }}
                        >
                            Đăng ký
                        </div>
                    )}
                </div>
            </div>
            <div className="md:block hidden">
                <AuthForm
                    visible={userData.isShowForm}
                    type={isType}
                    onCancel={() => {
                        dispatch(showModalForm(false));
                    }}
                />
            </div>
            <DrawerRight
                visible={isVisible}
                close={closeVisible}
                showForm={(e: boolean) => {
                    console.log(e);
                    setIsType(e);
                    dispatch(showModalForm(true));
                }}
            />
        </div>
    );
}

export default Header;

import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { Cryptor } from "./cryptor";
import { useAuth } from "@/hooks";
import alertUtils from "@/utils/alert";

const baseURL = `${process.env.NEXT_PUBLIC_API_URL}`;

const http = axios.create({
    method: "post", // default
    baseURL,
});

const aesCryptor = new Cryptor(process.env.NEXT_PUBLIC_AES_INIT_VECTOR ?? "", process.env.NEXT_PUBLIC_AES_SECRET_KEY ?? "");

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    path?: { [key: string]: string };
    query?: { [key: string]: string | number };
    body?: { request: any };
    header?: { [key: string]: string };
    headers: AxiosRequestHeaders;
}

http.interceptors.request.use(
    (config: CustomAxiosRequestConfig) => {
        if (config.path) {
            const pathKeys = Object.keys(config.path);
            pathKeys.forEach((key) => {
                config.url = config.url?.replace(`{${key}}`, config.path![key]);
            });
            delete config.path;
        }

        if (config.query) {
            config.url += `?${Object.entries(config.query)
                .map(([key, value]) => `${key}=${value}`)
                .join("&")}`;
            delete config.query;
        }

        if (config.body) {
            config.data = config.body.request;
            delete config.body;
        }

        if (config.header && config.header["Authorization"]) {
            // console.log("config.header: ", config.header);
            config.headers.Authorization = config.header["Authorization"];
            delete config.header;
        } else if (typeof window !== "undefined") {
            const token = localStorage.getItem("access_token");
            if (token && token !== "undefined" && token !== "null" && config.headers) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }

        // if (process.env.NODE_ENV !== "production" && config.url) {
        //     config.url += config.url.includes("?") ? "&env=local" : "?env=local";
        // }

        return config;
    },
    (error) => Promise.reject(error)
);

http.interceptors.response.use(
    (response) => {
        if (response.data.encryption === "aes256-cbc-pkcs5") {
            const decryptedData = aesCryptor.DesDecryption(response.data.data);
            response.data.data = JSON.parse(decryptedData);
            return response;
        }

        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // console.log("Error origin: ", error);
        console.log("Error: ", error.response.status, originalRequest.url, originalRequest._retry);

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const { getRefreshToken } = useAuth();
            const newAccessToken = await getRefreshToken();
            if (!newAccessToken) {
                clearLocalStorageAndRedirect();
                return Promise.reject(error);
            }
            localStorage.setItem("access_token", newAccessToken);
            http.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;

            return http(originalRequest);
        }
        return Promise.reject(error);
    }
    // async (error) => {
    //     const { response } = error;

    //     if (response?.status === 401 || response?.status === 403) {
    //         const { config } = error;
    //         // const refreshToken = localStorage.getItem("refresh_token");
    //         const { refreshToken } = useAuth();
    //         // await getRefreshToken();
    //         if (!refreshToken || (response && response.status !== 401)) {
    //             clearLocalStorageAndRedirect();
    //             return Promise.reject(error);
    //         }

    //         try {
    //             const { data: dataRefresh } = await http.post(
    //                 "/auth/refresh",
    //                 {},
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${refreshToken}`,
    //                     },
    //                 }
    //             );
    //             console.log(dataRefresh);

    //             updateLocalStorageAndHeaders(dataRefresh, config);
    //             return http(config);
    //         } catch (refreshError) {
    //             clearLocalStorageAndRedirect();
    //             return Promise.reject(error);
    //         }
    //     } else {
    //         // Show dialog error
    //     }

    //     return Promise.reject(error);
    // }
);

function showErrorDialog(title: string, message: string) {
    alert(`${title}: ${message}`);
}

function clearLocalStorageAndRedirect() {
    // localStorage.clear();
    Object.keys(localStorage).forEach((key) => {
        if (key !== "remember_user") {
            localStorage.removeItem(key);
        }
    });
    alertUtils.toastError("Bạn đã hết phiên đăng nhập vui lòng đăng nhập lại");
    setTimeout(() => {
        window.location.href = "/";
    }, 3000);
    // console.log("ra màn hình login");
}

function updateLocalStorageAndHeaders(dataRefresh: any, config: AxiosRequestConfig) {
    const { accessToken, refreshAccessToken } = dataRefresh;

    localStorage.setItem("_token", accessToken);
    localStorage.setItem("_refresh_token", refreshAccessToken);

    if (config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }

    window.location.href = "/";
}

export default http;

import http from "./axios_client";
import { apiCaller } from "./metruyenvl_caller";

const notificationApi = {
    async getNotification(page?: number, pageSize?: number) {
        return apiCaller("/notification", "get", {
            header: {
                Authorization: "",
            },
            query: {
                page: page,
                page_size: pageSize,
            },
        });
    },
    async readNotification() {
        return apiCaller("/notification/read", "post", {
            header: {
                Authorization: "",
            },
        });
    },
    // đánh dấu đã đọc tất cả
    async readAllNotification() {
        return apiCaller("/notification/read-all", "post", {
            header: {
                Authorization: "",
            },
        });
    },
};

export default notificationApi;
